.swiper-pagination-bullet-active {
	opacity: 1;
	background-color: #ad1515;
}

.swiper-container-custom {
	padding: 40px;
	padding-top: 8px;
	padding-bottom: 48px;
}

.swiper-button-prev,
.swiper-button-next {
	color: #ad1515;
	height: 100%;
	top: -45px;
	margin-top: 0;
	background-color: white;
}

.swiper-button-prev {
	left: 0px;
}
.swiper-button-next {
	right: 0px;
}
