@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap");

button.ess-help, 
.ess-portal-account,
.nav-dropdown-item {
	border: none;
	background-color: inherit;
	font-size: inherit;
	padding: 0;
	color: inherit;
	width: 100%;
	font-family: inherit;
	cursor: pointer;
    text-align: left;
}
button.ess-help li, 
.ess-portal-account li,
.nav-item li {
	font-size: inherit;
}