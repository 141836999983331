@import url("https://fonts.googleapis.com/css2?family=Oxygen:wght@100;300;400;500;700&display=swap");

:root {
	--color-white: white;
	--color-black: black;
}

body {
	overflow-x: hidden;
}

a {
	text-decoration: none;
	color: inherit;
}

img {
	width: 100%;
}

button.ess-help,
.subservice-nav-item {
	border: none;
	background-color: inherit;
	font-size: 24px;
	padding: 0;
	color: inherit;
	width: 100%;
	font-family: inherit;
	cursor: pointer;
}
button.ess-help li,
.subservice-nav-item li {
	font-size: inherit;
}

.hero-banner {
	width: 70%;
	margin: 0 auto;
}

@media screen and (max-width: 1080px) {
	.hero-banner {
		width: 100%;
	}
}

.blurhash {
	width: 100%;
}
