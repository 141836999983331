.hubspot {
	margin: 1rem auto;
	padding-left: 2rem;
	min-height: 428px;
}
.hubspotDesktop {
	width: 525px;
}
.hubspotMobile {
	width: 80vw;
}
